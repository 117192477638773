<template>
	<div class="grid dashboard">
		<div class="col-12 md:col-12">
			<div class="card height-100">
				<CrmDataTable baseEntityName="account" :options="CrmDataTable_account_options" :rows="5" :isHeaderVisible="true" @onRowSelect="onRowSelect_account" />
			</div>
		</div>
	
		<div class="col-12 md:col-12">
			<div class="card height-100">
				<CrmDataTable baseEntityName="salesorder" :options="CrmDataTable_salesorder_options" :rows="5" :isHeaderVisible="true" @onRowSelect="onRowSelect_account" />
			</div>
		</div>

		<div class="col-12 md:col-12">
			<div class="card height-100">
				<CrmDataTable baseEntityName="opportunity" :options="CrmDataTable_opportunity_options" :rows="5" :isHeaderVisible="true" @onRowSelect="onRowSelect_opportunity" />
			</div>
		</div>
	
	</div>
	
</template>
	
<script>

	export default {
		data() {
			return {
				CrmDataTable_account_options: {
					searchAttributes: ["name", "emailaddress1", "primarycontactidname", "bm_sehirname"],
				},
				CrmDataTable_salesorder_options: {
					searchAttributes: ["ordernumber", "customeridname", "bm_frmno", "bm_urunidname"],
					customFormat: [ { 'logicalName': 'salesorder', 'attributeName' : 'bm_geneltoplam', 'format' : 'n0'} ],
					views: [
						{ 'viewName': 'Etkin Siparişler', 'savedQueryId': 'E6F001DF-303C-447B-B184-0F65CD00C3F9' },
						{ 'viewName': 'Tüm Siparişler', 'savedQueryId': '4B2438F6-4949-484B-A272-6F694A2B2FAE' },
						{ 'viewName': 'Bu Ay Siparişler', 'savedQueryId': 'EBB35B76-9718-E811-B78C-E41F136A9174' },
						{ 'viewName': 'Bu Yıl Siparişler', 'savedQueryId': '42501F85-F591-E211-9AD9-E61F135C9177' },
						{ 'viewName': 'Geçen Yıl Siparişler', 'savedQueryId': '8BFBCC97-0DD3-E611-8A4F-E61F135C9177' },
					],
				},
				CrmDataTable_opportunity_options: {
					searchAttributes: ["name", "customeridname"],
				},
			}
		},
		methods: {
			onRowSelect_account(data) {
				this.$router.push({ name: 'firmadetay', params: { id: data.entityId } });
			},
			onRowSelect_salesorder(data) {
				this.$router.push({ name: 'salesorder', params: { id: data.entityId } });
			},
			onRowSelect_opportunity(data) {
				this.$router.push({ name: 'opportunity', params: { id: data.entityId } });
			},
		},
	}
	</script>
	
	<style lang="scss" scoped>
		
	</style>	